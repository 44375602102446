
.grey-background {
    border-radius: 20px;
    padding: 20px;
}
.sns-list {
    border-left: 2px solid #000;
    padding-left: 16px;
}
.v-data-table {
    ::v-deep {
        > .v-data-table__wrapper {
            > table {
                > thead {
                    > tr {
                        > th {
                            border-bottom: thin solid var(--v-grey-darken4) !important;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .grey-background {
        padding: 40px;
    }
}
@media (min-width: 1200px) {
    .grey-background {
        border-radius: 999px;
        padding: 60px 204px;
    }
}
