
.information-list {
    border-top: 1px solid #000;
    padding-top: 18px;
    > li {
        padding-bottom: 16px;
        &:last-child {
            padding-bottom: 0;
        }
    }
}
@media (min-width: 768px) {
    .information-list {
        padding-top: 24px;
        > li {
            padding-bottom: 20px;
        }
    }
}
