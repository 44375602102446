
.box-shadow {
    box-shadow: 0 0 14px 0 rgba(9, 20, 48, 0.1);
}
.system-title {
    font-family: "GmarketSans";
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
}
@media (min-width: 768px) {
    .core-value-image {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: -50vw;
            width: 150vw;
            height: 200px;
            background-color: #fff;
        }
    }
    .system-title {
        font-size: 26px;
        line-height: 1;
    }
}
@media (min-width: 1024px) {
    .rounded-lg-pill {
        border-radius: 999px !important;
    }
}
