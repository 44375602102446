
.content-list {
    > li {
        font-size: 14px;
        color: var(--v-grey-darken2);
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.children-list {
    margin-top: 32px;
    > li {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.children-title {
    font-size: 20px;
    border-width: 2px;
    padding: 6px 16px;
    margin-bottom: 18px;
    font-weight: 500;
}
.grandchild-list {
    > li {
        margin-bottom: 6px;
        font-weight: 500;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
@media (min-width: 768px) {
    .children-title {
        padding: 8px 18px;
    }
}
