
.v-divider {
    &--dark {
        border-color: var(--v-grey-darken4);
    }
}
.page-text {
    > p {
        margin-top: 18px;
        &:first-child {
            margin-top: 24px;
        }
    }
}
@media (min-width: 768px) {
    .page-text {
        > p {
            margin-top: 24px;
        }
    }
}
