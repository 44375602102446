
.v-divider {
    &--dark {
        border-color: var(--v-grey-darken4);
    }
}

.map-button {
    border-color: var(--v-grey-lighten3);
    border-radius: 8px;
    &::before {
        display: none;
    }
    &:hover {
        &::v-deep {
            .v-btn__content {
                color: var(--v-primary-base);
            }
        }
        border-color: var(--v-primary-base);
    }
}
.e-book-banner {
    height: 200px;
    background-position: bottom left !important;
}
@media (min-width: 768px) {
    .e-book-banner {
        background-position: center !important;
    }
}
