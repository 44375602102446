
.v-divider {
    &--dark {
        border-width: 1px;
        border-color: var(--v-grey-darken4);
    }
}
.map-dialog-card {
    &__header {
        height: 100%;
        display: flex;
        align-items: flex-end;
        color: #fff;
        padding: 12px 12px;
    }
    > .v-card__text {
        color: var(--v-grey-darken4);
        padding: 12px 12px 12px !important;
    }

    &__content {
        margin: -16px;
        > li {
            padding: 16px;
        }
    }
}
.group-table {
    > .v-data-table__wrapper {
        > table {
            > thead > tr > th,
            > tbody > tr > td {
                font-size: 16px;
            }
            > thead {
                > tr {
                    > th {
                        font-size: 16px;
                        border-bottom: thin solid var(--v-grey-darken4) !important;
                    }
                }
            }
        }
    }
}
.catchphrase-box {
    min-height: 100%;
    background-position-x: left !important;
    background-size: cover;
    line-height: 1.5;
    padding: 18px;
    padding-left: 36px;
}
@media (min-width: 1024px) {
    .map-dialog-card {
        &__header {
            padding: 24px 48px;
        }
        > .v-card__text {
            padding: 40px 60px 60px !important;
        }
        &__content {
            margin: -20px;
            > li {
                padding: 20px;
            }
        }
    }
    .group-table {
        > .v-data-table__wrapper {
            > table {
                > thead > tr > th,
                > tbody > tr > td {
                    font-size: 18px;
                }
            }
        }
    }
    .catchphrase-box {
        padding: 24px;
        padding-left: 48px;
    }
}
::v-deep(.v-textarea) {
    font-size: 20px;
}
