
.v-divider {
    &--dark {
        border-color: var(--v-grey-darken4);
    }
}
.history {
    > li {
        padding-bottom: 60px;
        &:last-child {
            padding-bottom: 0;
        }
    }
    &__year {
        font-weight: 700;
        color: var(--v-secondary-base);
        line-height: 1;
        padding-top: 16px;
        border-top: 2px solid currentColor;
    }
    &__month {
        font-weight: 500;
    }
    &__child {
        border-top: 1px solid var(--v-grey-lighten4);
        > li {
            padding: 20px 0;
            border-bottom: 1px solid var(--v-grey-lighten4);
        }
    }
    &__content {
        > li {
            margin-bottom: 6px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
@media (min-width: 768px) {
    .history {
        > li {
            padding-bottom: 80px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &__year {
            padding-top: 20px;
        }
        &__content {
            > li {
                margin-bottom: 8px;
            }
        }
    }
}
